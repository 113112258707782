import React, { FunctionComponent } from "react";
import Divider from "../Divider/Divider";
import styles from "./Title.module.css";

const Title: FunctionComponent<{ title: string; noline?: boolean }> = ({
  title,
  noline,
}) => (
  <div className={styles[noline ? "" : "title-wrapper"]}>
    <h1 className={styles["title-text"]}>{title}</h1>
    {!noline && <Divider />}
  </div>
);

export default Title;
