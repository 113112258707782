import React, { FunctionComponent, useCallback, useState } from "react";
import styles from "./BaseScreen.module.css";
import dynamic from "next/dynamic";
import Head from "next/head";

const StarsBackground = dynamic(
  () => import("@bsg/client/components/StarsBackground"),
  {
    ssr: false,
  }
);

type BaseScreenProps = {
  showHex?: boolean;
  showStars?: boolean;
  showEntities?: boolean;
  sidebar?: React.ReactFragment;
  headerTitle?: string;
};

const Stars: FunctionComponent<{ showEntities: boolean }> = ({
  showEntities,
}) => {
  const [visible, setVisible] = useState(false);
  const fadeIn = useCallback(() => setVisible(true), []);
  return (
    <div
      style={{
        top: 0,
        left: 0,
        position: "fixed",
        zIndex: 1,
        width: "100vw",
        height: "100vh",
        backgroundColor: "#272830",
      }}
    >
      <div
        style={{
          opacity: visible ? 1 : 0,
          transition: "opacity 3s",
        }}
      >
        <StarsBackground onMount={fadeIn} showEntities={showEntities} />
      </div>
    </div>
  );
};

const BaseScreen: FunctionComponent<BaseScreenProps> = ({
  children,
  showHex,
  showStars,
  showEntities,
  sidebar,
  headerTitle,
}) => {
  return (
    <div className={styles["wrapper"]}>
      {headerTitle && (
        <Head>
          <meta property="og:title" content={headerTitle} key="ogtitle" />
          <title key={"title"}>{headerTitle}</title>
        </Head>
      )}
      {showStars && <Stars showEntities={showEntities} />}
      <div
        className={`${styles["content"]} ${
          showHex ? styles["hex-content"] : ""
        }`}
      >
        {sidebar && (
          <div className={`${styles["side-bar-container"]}`}>
            <div className={`${styles["side-bar-content"]}`}>{sidebar}</div>
          </div>
        )}

        {showHex ? (
          <>
            <div
              style={{
                position: "absolute",
                zIndex: 2,
              }}
              className={styles["hex"]}
            >
              <div
                style={{
                  zIndex: 4,
                  width: "100%",
                  position: "absolute",
                  top: "-20%",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "140%",
                  display: "flex",
                }}
              >
                <div style={{ position: "relative", display: "flex" }}>
                  {children}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                zIndex: 1,
              }}
              className={`${styles["hex"]} ${styles["background"]}`}
            ></div>
          </>
        ) : (
          <div
            style={{
              zIndex: 3,
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default BaseScreen;
