import React, { FunctionComponent } from "react";
const HexSvg: FunctionComponent<{
  style?: React.CSSProperties;
  className?: string;
  stroke?: string;
  fill?: string;
}> = ({ style, className, fill = "black", stroke = "black" }) => (
  <svg
    className={className}
    style={style}
    width="16"
    height="16"
    viewBox="0 0 29 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16803 0L21.5041 2.464e-07L28.6721 12.4154L21.5041 24.8308L7.16803 24.8308L0 12.4154L7.16803 0Z"
      fill={fill}
      stroke={stroke}
      strokeWidth={2}
    />
  </svg>
);

export default HexSvg;
