import React, { useMemo } from "react";
type BlurCardProps = { style?: React.CSSProperties; dark?: boolean };

const BlurCard: React.FC<BlurCardProps> = ({
  children,
  style = {},
  dark = false,
}) => {
  const BLUR_SUPPORTED = useMemo(
    () => CSS.supports("backdrop-filter: blur(2px)"),
    []
  );
  return (
    <div
      style={{
        backdropFilter: `blur(2px) opacity(0.9)`,
        background: dark
          ? BLUR_SUPPORTED
            ? `rgba(0, 0, 0, 0.6)`
            : `rgba(0, 0, 0, 0.8)`
          : BLUR_SUPPORTED
          ? `rgba(124, 124, 124, 0.4)`
          : `rgba(180, 180, 180, 0.6)`,
        padding: 12,
        borderRadius: 16,

        margin: 0,
        color: dark ? "#fff" : "#000",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
export default BlurCard;
