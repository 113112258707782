import {
  Tile,
  Color,
  Resource,
  WaterVariant,
  Vector3_serialized,
  DevCardType,
} from "./types";

export { COLOR_MAP } from "./colors";

export const DEV_CARD_TYPE_AMOUNTS: {
  [d in DevCardType]: { count: number; variants: number[] };
} = {
  KNIGHT: {
    count: 14,
    variants: [1, 2, 3],
  },
  MONOPOLY: {
    count: 2,
    variants: [1],
  },
  ROAD_BUILDING: {
    count: 2,
    variants: [1],
  },
  YEAR_OF_PLENTY: {
    count: 2,
    variants: [1],
  },
  VICTORY_POINT: {
    count: 5,
    variants: [1, 2, 3, 4, 5],
  },
};

export const ALL_RESOURCES: Resource[] = [
  "WOOD",
  "SHEEP",
  "BRICK",
  "ORE",
  "WHEAT",
];

export const TILE_AMOUNTS = {
  BRICK: 3,
  ORE: 3,
  WHEAT: 4,
  SHEEP: 4,
  WOOD: 4,
  DESERT: 1,
};

export const ALL_TILES: Tile[] = [...ALL_RESOURCES, "WATER", "DESERT"];

export const ALL_COLORS: Color[] = ["BLUE", "RED", "ORANGE", "WHITE"];

export const ALL_TILE_BORDERS: WaterVariant[] = [
  "T",
  "T_TR",
  "TR",
  "TR_BR",
  "BR",
  "BR_B",
  "B",
  "B_BL",
  "BL",
  "BL_TL",
  "TL",
  "TL_T",
];

const playerDistance = 14; // based on board size.
export const PLAYER_POSITIONS: Record<Color, Vector3_serialized> = {
  BLUE: [playerDistance, 8, 0],
  RED: [0, 8, -playerDistance],
  WHITE: [-playerDistance, 8, 0],
  ORANGE: [0, 8, playerDistance],
};

/**
 * In radians.
 *
 * Not used for calculating initial rotation (lookAt origin is used),
 * but used for orienting visuals towards player starting points. (e.g. Value Chips)
 */
export const PLAYER_ROTATIONS: Record<Color, number> = {
  WHITE: 0,
  ORANGE: Math.PI / 2,
  BLUE: Math.PI,
  RED: (3 * Math.PI) / 2,
};

export const MAX_NAME_LENGTH = 16;
