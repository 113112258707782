import React, { FunctionComponent } from "react";
import { Color } from "@bsg/shared/types";
import Card from "./Card";
import styles from "./CardSelector.module.css";

const CardOrder: Color[] = ["ORANGE", "WHITE", "RED", "BLUE"];

type CardSelectorProps = {
  type: "join" | "host";
  onPress: (color: Color) => void;
  disabledCards: "all" | Color[];
};
const CardSelector: FunctionComponent<CardSelectorProps> = ({
  type,
  onPress,
  disabledCards,
}) => {
  const allDisabled = disabledCards === "all";
  return (
    <div className={styles["CardSelector"]}>
      {CardOrder.map((color) => {
        const isDisabled = allDisabled || disabledCards.includes(color);
        return (
          <Card
            key={color}
            disabled={isDisabled}
            type={type}
            color={color}
            onPress={() => (isDisabled ? null : onPress(color))}
          />
        );
      })}
    </div>
  );
};

export default CardSelector;
