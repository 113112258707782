import React, { FunctionComponent, useState } from "react";
import { Color } from "@bsg/shared/types";
import styles from "./Card.module.css";

const colorToBackground = {
  RED: "#F22B29",
  BLUE: "#0080FF",
  WHITE: "white",
  ORANGE: "orange",
};

type CardProps = {
  color: Color;
  onPress: () => void;
  type: "join" | "host";
  disabled?: boolean;
};

const Card: FunctionComponent<CardProps> = ({
  color,
  type,
  onPress,
  disabled,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      style={{
        borderColor: isHovered ? colorToBackground[color] : "transparent",
      }}
      className={styles["CardContainer"]}
    >
      <div
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundColor: colorToBackground[color],
          position: "relative",
        }}
        className={`${styles["Card"]} ${disabled ? styles["disabled"] : ""}`}
      >
        <p
          className={`${styles["join-text"]} ${
            disabled || !isHovered ? styles["hide"] : ""
          }`}
        >
          {type === "join" ? "Join" : "Host"} as <b>{color}</b>
        </p>
      </div>
    </div>
  );
};

export default Card;
