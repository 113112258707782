import styles from "./Input.module.css";
import React, { FunctionComponent } from "react";
import Loader from "../Loader/Loader";

type InputProps = {
  placeHolder: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  loading?: boolean;
  error?: string;
  inputStyle?: React.CSSProperties;
  dark?: boolean;
};
const Input: FunctionComponent<InputProps> = ({
  placeHolder,
  onChange,
  value,
  loading,
  error,
  inputStyle,
  dark,
}) => (
  <div style={{ position: "relative" }}>
    <input
      className={`${styles["PrimaryInput"]} ${dark ? styles["dark"] : ""}`}
      style={inputStyle}
      placeholder={placeHolder}
      onChange={onChange}
      value={value}
    />
    {error && (
      <h2
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          paddingTop: 4,
        }}
        className={styles["errorMessage"]}
      >
        {error}
      </h2>
    )}
    <div
      style={{
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0%)",
        paddingTop: 10,
      }}
    >
      {/* TODO: make these nice. These are hacks to having prototype screen state */}
      {loading && <Loader />}
    </div>
  </div>
);

export default Input;
