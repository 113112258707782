import React, { FunctionComponent, useState, useEffect } from "react";
import styles from "./Button.module.css";
import HexSvg from "../Icons/HexSvg";
import { COLOR_MAP } from "@bsg/shared/constants";
import Link from "next/link";

const ButtonIcon = ({
  visible,
  color,
}: {
  visible: boolean;
  color: string;
}): JSX.Element => (
  <HexSvg
    className={`${styles["ButtonIcon"]} ${visible ? "" : styles["hide"]}`}
    style={{ paddingLeft: 0, paddingRight: 0, overflow: "visible" }}
    fill={color}
    stroke="black"
  />
);

type ButtonProps = {
  title: string;
  onPress: () => void;
};

const randomColor = () => {
  const colorArray = Object.values(COLOR_MAP);
  const numColors = colorArray.length;
  return colorArray[Math.floor(Math.random() * numColors)];
};

const Button: FunctionComponent<ButtonProps> = ({ title, onPress }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [color, setColor] = useState(randomColor());
  useEffect(() => {
    if (isHovered) {
      setColor(randomColor());
    }
  }, [isHovered]);
  return (
    <div className={styles["button-container"]}>
      <ButtonIcon color={color} visible={isHovered} />
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={styles["Button"]}
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          onPress();
        }}
      >
        {title}
      </button>
      <ButtonIcon color={color} visible={isHovered} />
    </div>
  );
};

type ButtonLinkProps = {
  title: string;
  link: string;
};
export const LinkButton: FunctionComponent<ButtonLinkProps> = ({
  title,
  link,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [color, setColor] = useState(randomColor());
  useEffect(() => {
    if (isHovered) {
      setColor(randomColor());
    }
  }, [isHovered]);
  return (
    <Link href={link}>
      <a
        style={{
          textDecoration: "none",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={styles["button-container"]}>
          <ButtonIcon color={color} visible={isHovered} />
          <div className={styles["Button"]}>{title}</div>
          <ButtonIcon color={color} visible={isHovered} />
        </div>
      </a>
    </Link>
  );
};

export default Button;
